<template>
<div>
    <label>{{ label }}</label>
    <div class="container-fluid" ref="editor" style="min-height:35px;">
        {{ value_ }}
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        props: ['value', 'label', 'tools'],
        data()
        {
            return {
                id: Date.now() + '_' + parseInt(Math.random()*1000),
                value_: this.value
            };
        },
        mounted()
        {
            $(this.$refs.editor).kendoEditor({
                resizable: {
                    content: false,
                    toolbar: true
                },
                change: this.onChange,
                select: this.onSelect,
                execute: this.onExecute,
                paste: this.onPaste,
                tools: this.tools,
                fontSize: [
					{ text: "1 (8pt)",  value: "xx-small" },
					{ text: "2 (10pt)", value: "x-small" },
					{ text: "3 (12pt)", value: "small" },
					{ text: "4 (14pt)", value: "medium" },
					{ text: "5 (18pt)", value: "large" },
					{ text: "6 (24pt)", value: "x-large" },
					{ text: "7 (36pt)", value: "xx-large" },
					{ text: "8 (48pt)", value: "48px" },
					{ text: "9 (72pt)", value: "72px" }
                ]
            });
            let editor = $(this.$refs.editor).data("kendoEditor");
            editor.value(this.value_);
        },
        methods:
        {
            onChange(e)
            {
                let editor = $(this.$refs.editor).data("kendoEditor");
                this.value_ = editor.value();
                this.$emit('input', this.value_);
                this.$emit('change', this.value_);
            },
            onSelect(e)
            {
                //console.log("selection change");
            },
            onExecute(e)
            {
                //console.log("command :: " + e.name);
            },
            onPaste(e)
            {
                //console.log("paste :: " + kendo.htmlEncode(e.html));
            }
        }
    }
</script>

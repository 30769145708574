<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-12" v-for="(item, index) in tags">
            <input v-model="item.value.label" class="form-control" type="text" disabled /><br />
            <KendoValueComponent v-model="item.value.label" v-on:change="notifyChanges" label="" :tools="tools"/><br />
            
            <input v-model="item.value.url" class="form-control" v-on:change="notifyChanges" list="pages" /><br />
            <button class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">Tag verwijderen</button>
        </div>
        <div class="col-xs-12" style="margin-top:15px;">
            <button class="btn btn-sm btn-primary" @click.prevent="addItem">Tag toevoegen</button>
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            },
            addItem()
            {
                this.tags.push({
                    value: {
                        label: "Tekst",
                        url: "https://google.com"
                    }
                });
                this.notifyChanges();
            },
            deleteItem(index)
            {
                this.tags.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", {
                        name: "custom",
                        tooltip: "Wordbreak toevoegen",
                        exec: function (e) {
                            var editor = $(this).data("kendoEditor");
                            editor.exec("inserthtml", { value: "&shy;" });
                        }
                    }, "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

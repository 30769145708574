<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="form.anchor" v-on:change="notifyChanges" />
        </div>
                <div class="col-xs-12 col-md-6">
                    <div class="form-group">
                        <label>Redirectpagina</label>
                        <input list="pages" class="form-control" v-model="form.redirectURL" placeholder="/">
                    </div>
                    <div class="form-group">
                        <label>Formulier titel</label>
                        <input type="text" class="form-control" v-model="form.formTitle" placeholder="Opgaveformulier">
                    </div>
                    <div class="form-group">
                        <label>Knop tekst</label>
                        <input type="text" class="form-control" v-model="form.buttonText" placeholder="Versturen">
                    </div>
                    <div class="form-group" style="padding: 20px; border: 1px dotted orange;" v-for="(cf, cfIndex) in form.customfields">
                        <a style="float: right;" class="btn btn-xs btn-danger" v-on:click.prevent="deleteCustomFieldIndex(cfIndex)">verwijder</a>
                        <label>Custom field <input type="text" v-model="cf.name"></label><br />
                        
                        <label>Type</label>
                        <select v-model="cf.type" class="form-control">
                            <option value="text">Tekst veld</option>
                            <option value="integer">Numeriek veld</option>
                            <option value="radio">Een keuze</option>
                            <option value="checkbox">Meerkeuze</option>
                            <option value="groep">Groep</option>
                        </select>
                        
                        <div v-if="cf.type != 'groep'">
                        <label><input type="checkbox" v-model="cf.required"> Verpicht?</label>
                        </div>

                        <div v-if="cf.type != 'integer' && cf.type != 'text'">
                            <label>Label</label>
                            <input type="text" v-model="cf.label" class="form-control">
                        </div>

                        <div v-if="cf.type == 'integer' || cf.type == 'text'">
                            <label>Placeholder</label>
                            <input type="text" v-model="cf.placeholder" class="form-control">
                        </div>

                        <div v-if="cf.type == 'radio' || cf.type == 'checkbox'">
                            <ul>
                                <li v-for="(option, optionIndex) in cf.options"><input type="text" v-model="option.value"> <a v-on:click.prevent="cf.options.splice(optionIndex, 1)">verwijder</a></li>
                                <li><input type="text" v-on:keydown.enter.prevent="cf.options.push({value: $event.target.value}); $event.target.value=''"></li>
                            </ul>
                        </div>

                    </div>
                    <div class="form-group">
                        <a class="btn btn-xs btn-success" v-on:click.prevent="addCustomField">Custom veld toevoegen</a>
                    </div>
                </div>
                <div class="col-xs-12 col-md-6">
                    <div class="mailtags">
                        Gebruik <b>#naam#, #email#, #tel#, #bedrijfsnaam#</b>
                    </div>
                    <div class="form-group">
                        <label><input type="checkbox"  v-model="form.ownermailenabled"> Mail naar speedbooks?</label>
                    </div>
                    <div class="form-group" v-if="form.ownermailenabled">
                        <label>Mail naar speedbooks - onderwerp</label>
                        <input type="text" class="form-control" v-model="form.ownersubject" placeholder="Onderwerp">
                    </div>
                    <div class="form-group" v-if="form.ownermailenabled">
                        <label>Speedbooks e-mailadres</label>
                        <input type="text" class="form-control" v-model="form.supportEmail" placeholder="support@speedbooks.com">
                    </div>
                    <div class="form-group" v-if="form.ownermailenabled">
                        <KendoValueComponent v-model="form.owneremail" label="Email naar eigenaar" :tools="tools"/>
                    </div>
                    <div class="form-group">
                        <label><input type="checkbox" v-model="form.customermailenabled"> Mail naar klant? </label>
                    </div>
                    <div class="form-group" v-if="form.customermailenabled">
                        <label>Mail naar klant - onderwerp</label>
                        <input type="text" class="form-control" v-model="form.customersubject" placeholder="Onderwerp">
                    </div>
                    <div class="form-group" v-if="form.customermailenabled">
                        <KendoValueComponent v-model="form.customeremail" label="Email naar klant" :tools="tools"/>
                    </div>
                </div>
                

    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        watch: {
            form: {
                deep: true,
                handler(oldValue, newValue){
                    this.notifyChanges();
                }
            }
        },
        methods: {
            notifyChanges()
            {
                this.$emit('change', this.form);
            },
            deleteCustomFieldIndex(index)
            {
                this.form.customfields.splice(index, 1);
            },
            addCustomField()
            {
                var newField = {
                    type: 'integer',
                    required: false,
                    placeholder: '',
                    label: '',
                    name: '',
                    options: []
                };
                this.form.customfields.push(newField);
            }
        },
        data()
        {
            debugger;
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
            };
            obj.form = JSON.parse(JSON.stringify(this.data));
            return obj;
        },
        props: ['data']
    }
</script>
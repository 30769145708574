<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-6">
            <div class="row">
                <div class="col-xs-12" v-for="(item, index) in buttons">
                    <ButtonComponent v-model="item.value" @change="notifyChanges" overrideStyle="background:orange!important;color:#fff!important;" />
                    <button class="btn btn-sm btn-danger" @click.prevent="deleteItem(index)">Knop verwijderen</button>
                </div>
                <div class="col-xs-12" style="margin-top:15px;">
                    <button class="btn btn-sm btn-primary" @click.prevent="addItem">Knop toevoegen</button>
                </div>
            </div>
        </div>
        <div class="col-xs-6">
        <ImageUploadComponent label="Afbeelding achtergrond" v-model="mainImage" containerStyle="float:left;border:1px solid #ccc;" />
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            },
            addItem()
            {
                this.buttons.push({
                    value: {
                        label: "Tekst",
                        url: "https://google.com"
                    }
                });
                this.notifyChanges();
            },
            deleteItem(index)
            {
                this.buttons.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

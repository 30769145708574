<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>

        <div class="col-xs-6">
            <div class="form-group">
            <ImageUploadComponent label="Afbeeldingsicoon links" v-model="imageIconLeft" containerStyle="float:left;border:1px solid green;" />
            </div>
            <div class="form-group" style="clear:both;">
                <label>Titel links</label>
                <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="titleLeft" placeholder="Titel links">
            </div>
            <div class="form-group">
                <label>Tekst links</label>
                <KendoValueComponent v-model="textLeft" :tools="tools"/>
                <!--<textarea class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="textLeft" placeholder="Tekst links"></textarea>-->
            </div>

            <ButtonComponent v-model="buttonLeft" />
        </div>

        <div class="col-xs-6">
            <div class="form-group">
            <ImageUploadComponent label="Afbeeldingsicoon rechts" v-model="imageIconRight" containerStyle="float:left;border:1px solid green;" />
            </div>
            <div class="form-group" style="clear:both;">
                <label>Titel rechts</label>
                <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="titleRight" placeholder="Titel rechts">
            </div>
            <div class="form-group">
                <label>Tekst rechts</label>
                <KendoValueComponent v-model="textRight" :tools="tools"/>
                <!--<textarea class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="textRight" placeholder="Tekst rechts"></textarea>-->
            </div>

            <ButtonComponent v-model="buttonRight" />
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

<template>
<div>
  <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="onChange" />
        </div>
</div>

    <div class="form-group" style="margin-top:15px;">
      <label for="tekst_groot">Logo's titel</label>
      <input v-model="localData.logosTitle" class="form-control" @change="onChange"/>
    </div>
    
    <ul>
      <li v-for="(obj, objIndex) in localData.logos">
        <div class="form-group" style="margin-top:15px;">
            <ImageUploadComponent label="Logo" @change="onChange($event)" :uid="localData.logos.length+'_'+objIndex" v-model="localData.logos[objIndex].image"></ImageUploadComponent>
            <input list="pages" class="form-control" v-model="obj.link" @change.prevent="onChange" placeholder="https://google.com"><br />
            <a class="btn btn-xs btn-danger" v-on:click.prevent="deleteLogo(objIndex)">Verwijder</a>
        </div>
      </li>
      <li>
        <a class="btn btn-xs btn-primary" v-on:click="addLogo">Pakket met afbeelding toevoegen</a>
      </li>
    </ul>
    
    <div class="form-group" style="margin-top:15px;">
      <label for="tekst_groot">Links titel</label>
      <input v-model="localData.linksTitle" class="form-control" @change="onChange"/>
    </div>

    <ul>
      <li v-for="(obj, objIndex) in localData.links">
        <div class="form-group" style="margin-top:15px;">
            <ButtonComponent label="Logo" @change="onChange($event)" v-model="obj.link" :uid="localData.links.length+'_'+objIndex"></ButtonComponent><br />
            <a class="btn btn-xs btn-danger" v-on:click.prevent="deleteLink(objIndex)">Verwijder</a>
        </div>
      </li>
      <li>
        <a class="btn btn-xs btn-primary" v-on:click="addLink">Pakket zonder afbeelding toevoegen</a>
      </li>
    </ul>

</div>
</template>

<script type="application/javascript">
    export default {
        props: ['data'],
        data()
        {
            return {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                localData: JSON.parse(JSON.stringify(this.data))
            }
        },
        methods:
        {
            addLogo()
            {
              this.localData.logos.push({
                image: {
                  path: "",
                  alt: '',
                  visible: true
                },
                link: ''
              });
              this.notifyChanges();
            },
            addLink()
            {
              this.localData.links.push({
                link: {
                  label: "",
                  url: ''
                }
              });
              this.notifyChanges();
            },
            deleteLink(objIndex)
            {
              this.localData.links.splice(objIndex, 1);
              Vue.set(this.localData, 'links', this.localData.links);
              this.notifyChanges();
            },
            deleteLogo(objIndex)
            {
              this.localData.logos.splice(objIndex, 1);
              Vue.set(this.localData, 'logos', this.localData.logos);
              this.notifyChanges();
            },
            onChange(e)
            {
                this.notifyChanges();
            },
            onInput(e)
            {
                this.notifyChanges();
            },
            notifyChanges: function()
            {
              this.$emit('change', this.localData);
            }
        }
    }
</script>

<template>
  <div class="ImageUploadComponent">
    <div v-if="selecting" class="gallery">
      <div class="close" v-on:click="selecting = false"></div>
      <!--<p style="font-weight: bold; font-size: 14px">Gallerij</p>-->

      <div class="form-group">
        <div class="image-box" v-for="(img, imgIndex) in images">
          <img :src="img.path" @click.prevent="selectImage(img)" />
          <div class="delete" v-on:click="deleteImageIndex(imgIndex)">verwijderen</div>
        </div>
      </div>
      <div class="form-group" style="padding-top: 15px; clear: both">
        <button
          class="btn btn-sm btn-danger"
          @click.prevent="selecting = false"
        >
          Annuleren
        </button>
      </div>
    </div>
    <div v-if="!selecting" ref="blok">
      <div class="form-check image-toggle">
        <input
          type="checkbox"
          class="form-check-input"
          :id="'file-input-image-visible-' + id"
          v-model="value_.visible"
          v-on:change="onPathChanged"
          v-on:input="onPathChanged"
        />
        <label class="form-check-label" :for="'file-input-image-visible-' + id"
          >Zichtbaar</label
        >
      </div>

      <!--
    <div class="form-group">
        <label :for="'file-input-'+id" style="font-weight:bold;">{{ label }}</label>
    </div>
    -->

      <div class="row" v-if="value_.visible" style="margin-top: 20px">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <div class="form-group">
            <label>Afbeelding uploaden</label>
            <input
              type="file"
              class="form-control-file form-control"
              accept="image/*"
              @change="uploadImage"
              :id="'file-input-' + id"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <div class="form-group">
            <label>Afbeelding beschrijving</label>
            <input
              type="text"
              v-model="value_.alt"
              class="form-control"
              @change.prevent="onPathChanged"
            />
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <div class="form-group">
            <label>Afbeelding URL</label>
            <input
              type="text"
              v-model="value_.path"
              class="form-control"
              @change.prevent="onPathChanged"
            />
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group">
            <button
              @click.prevent="imageSelector"
              class="btn btn-sm btn-primary w-100"
            >
              Kies een afbeelding uit de gallerij
            </button>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="value_.visible">
        <img :src="value_.path" style="max-height: 100px" />
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
export default {
  props: ["value", "label", "containerStyle", "layout"],
  data() {
    return {
      id: Date.now() + "_" + parseInt(Math.random() * 1000),
      value_: JSON.parse(JSON.stringify(this.value)),
      images: [],
      selecting: false,
    };
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        this.value_ = JSON.parse(JSON.stringify(newValue));
        //debugger;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    selectImage(image) {
      this.value_.path = image.path;
      this.selecting = false;
      this.onPathChanged();

      this.$nextTick(() => {
        this.$refs.blok.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    deleteImageIndex(imageIndex)
    {
      const URL = "/pageblockimages";
      var id = this.images[imageIndex].id;

      this.images.splice(imageIndex, 1);

      let payload = {
        image_id: id
      };

      axios.delete(URL, {data: payload}).then((response) => {
        console.log(response);
      });

    },
    imageSelector() {
      this.selecting = true;
      this.fetchImageData();
    },
    fetchImageData() {
      axios
        .get("/pageblockimages")
        .then((response) => {
          console.log(response);
          this.images = response.data.images;
        })
        .catch((err) => {
          alert(
            "Er is iets fout gegaan met het ophalen van de afbeeldingen. Error: " +
              err
          );
        });
    },
    onPathChanged(event) {
      this.$emit("input", this.value_);
      this.$emit("change", this.value_);
    },
    uploadImage(event) {
      const URL = "/pageblockimages";

      let data = new FormData();
      let file = event.target.files[0];

      data.append("name", file.name);
      data.append("file", file);
      let config = {
        header: {
          //'Content-Type' : file.type
          "Content-Type": "multipart/form-data",
        },
      };
      axios.post(URL, data, config).then((response) => {
        console.log(this);
        let imagePath = response.data.path;
        this.value_.path = imagePath;
        this.$emit("input", this.value_);
        this.$emit("change", this.value_);
      });
    },
  },
};
</script>

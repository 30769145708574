<template>
  <div class="pagelist-component">
    <div class="search-box">Zoeken <input type="text" v-model="searchField" v-on:keydown.enter.prevent="function(){return false;}"></div>

    <div class="page" :style="{'margin-left': getRealPageIndent(pageIndex)}" v-for="(realpage, pageIndex) in realpages" v-if="searchMatch(realpage) || searchMatchArray(canonicals[realpage.url]) || searchMatchArray(redirects[realpage.url])">
      <div class="page-bar" v-on:click="onClickPageIndex(pageIndex)">
        <div :class="['page-label', 'type-'+realpage.pagina_type]"><span v-if="realpage.published == 0">(concept) </span>{{ realpage.titel }}</div>
        <div :class="{tag:true, slug: true, inactive: !searchMatch(realpage)}">slug</div>
        <div :class="{tag: true, redirect:true, inactive: pageRedirectsInactive(realpage.url)}">redirect ({{ redirects[realpage.url].length }})</div>
        <div :class="{tag: true, canonical: true, inactive: pageCanonicalsInactive(realpage.url)}">canonical ({{ canonicals[realpage.url].length }})</div>
        
      </div>
      <div class="extras" v-if="openPageIndexes.indexOf(pageIndex) != -1 || (searchField != '' && (searchMatch(realpage) || searchMatchArray(canonicals[realpage.url]) || searchMatchArray(redirects[realpage.url])))">
        <div class="url-container">
          <a :href="'/website/hoofdmenu/details/'+realpage.id" target="_blank">{{ realpage.url }}</a>
        </div>

        <div class="redirects-container" v-if="redirects[realpage.url].length > 0">
          <ul>
            <li v-for="redirect in redirects[realpage.url]"><a :href="'/website/hoofdmenu/details/'+redirect.id" target="_blank"><span v-if="redirect.published == 0">(concept) </span>{{ redirect.url }}</a></li>
          </ul>
        </div>

        <div class="canonicals-container" v-if="canonicals[realpage.url].length > 0">
          <ul>
            <li v-for="canonical in canonicals[realpage.url]"><a :href="'/website/hoofdmenu/details/'+canonical.id" target="_blank"><span v-if="canonical.published == 0">(concept) </span>{{ canonical.url }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="application/javascript">
    export default {
        props: ['realpages','canonicals','redirects','frontendurl'],
        mounted()
        {
            
        },
        data()
        {
            return {
              openPageIndexes: [],
              searchField: "",
            };
        },
        methods: {
            onClickPageIndex: function(pageIndex)
            {
              this.openPageIndexes = [pageIndex];
            },
            searchMatch: function(page)
            {
              if (this.searchField == "")
              {
                return true;
              }
              if (page.titel !== null)
              {
                var matchTitle = (page.published==1?"":"(concept) ")+page.titel;
                if (matchTitle.toLowerCase().indexOf(this.searchField.toLowerCase()) != -1)
                {
                    return true;
                }
              }

              var matchURL = (page.published==1?"":"(concept) ")+page.url;
              if (matchURL.indexOf(this.searchField.toLowerCase()) != -1)
              {
                return true;
              }
              return false;
            },
            searchMatchArray: function(pages)
            {
              for (var i=0; i<pages.length; i++)
              {
                if (this.searchMatch(pages[i]))
                {
                  return true;
                }
                return false;
              }
            },
            getRealPageIndent: function (url)
            {
              var indent = 0;
              var keys = Object.keys(this.realpages);
              var pageIndex = keys.indexOf(url);

              for (var i=pageIndex-1; i>=0; i--)
              {
                if (url.indexOf(keys[i]) == 0)
                {
                  indent++;
                }
              }

              return (indent*20)+'px';
            },
            pageRedirectsInactive: function(url)
            {
              return this.redirects[url].length == 0 || (this.searchField != '' && !this.searchMatchArray(this.redirects[url]));
            },
            pageCanonicalsInactive: function(url)
            {
              return this.canonicals[url].length == 0 || (this.searchField != '' && !this.searchMatchArray(this.canonicals[url]));
            }
        }
        
    }
</script>

<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xs-12">
                <label>Anchor</label>
                #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
            </div>
            <div class="col-xs-12">
                <label>Aantal items boven de vouw</label>
                <input class="form-control inlined" type="number" v-model="foldcount" v-on:change="notifyChanges" />
            </div>
            <div :class="getRowClass" v-for="(p, pakketIndex) in pakketten">
                <div class="form-group">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" :id="'checkbox-pakket-featured-'+pakketIndex" v-model="p.featured" v-on:change="notifyChanges" v-on:input="notifyChanges">
                        <label class="form-check-label" :for="'checkbox-pakket-featured-'+pakketIndex">Pakket uitgelicht</label>
                    </div>

                    <button class="btn btn-sm btn-danger" @click.prevent="deletePakket(pakketIndex)"><i class="fas fa-times"></i> Verwijder pakket</button>
                </div>

                <div class="form-group">
                    <label>Uitgelicht kop</label>
                    <input type="text" :disabled="!p.featured" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.featured_text" placeholder="">
                </div>

                <div class="form-group">
                    <label>Stijl</label>
                    <select class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.color">
                        <option value="none">-- kies een kleur --</option>
                        <option value="paars">Paars</option>
                        <option value="blauw">Blauw</option>
                        <option value="oranje">Oranje</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Titel 1</label>
                    <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.titel" placeholder="">
                </div>
                <div class="form-group">
                    <label>Subtitel 1</label>
                    <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.subtitel" placeholder="">
                </div>
                <div class="form-group">
                    <label>Titel 2</label>
                    <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.titel2" placeholder="">
                </div>
                <div class="form-group">
                    <label>Subtitel 2</label>
                    <input type="text" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.subtitel2" placeholder="">
                </div>

                <div class="row" >
                    <div v-for="(item, index) in p.list" style="padding:10px;border:1px dashed #ccc;">
                        <div class="list-item" >
                            <span class="input-group-btn">
                                <button class="btn btn-sm btn-danger" @click.prevent="deleteItem(p.list, index)"><i class="fas fa-times"></i></button>
                            </span>
                        
                            <label>
                                Check <input type="checkbox" class="form-check-input" v-model="item.checked" v-on:change="notifyChanges" v-on:input="notifyChanges" style="margin-right:5px;">
                            </label>

                            <label>
                                Bold <input type="checkbox" class="form-check-input" v-model="item.bold" v-on:change="notifyChanges" v-on:input="notifyChanges" style="margin-right:5px;">
                            </label>
                        </div>
                        <div class="input-group">
                            <textarea class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="item.tekst" placeholder="Tekst"></textarea>
                        </div>
                        <div class="input-group">
                            <label>
                                Info box
                            </label>
                            <KendoValueComponent v-model="item.info" :tools="tools"/>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-sm btn-primary" @click.prevent="addItem(p.list)">Optie toevoegen</button>
                    </div>
                </div>
                <div class="form-group">
                    <label>Vouw openen</label>
                    <input type="text" :disabled="p.list.length <= foldcount" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.fold_description_more" placeholder="">
                </div>
                <div class="form-group">
                    <label>Vouw sluiten</label>
                    <input type="text" :disabled="p.list.length <= foldcount" class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="p.fold_description_less" placeholder="">
                </div>

                <div class="form-group">
                    <ButtonComponent v-model="p.button" @change="notifyChanges" />
                </div>
            </div>
        </div>
        <div class="row" style="margin-top:15px;">
            <button class="btn btn-sm btn-primary" style="float:right;" @click.prevent="addPakket">Pakket toevoegen</button>
        </div>
    </div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
        },
        computed: {
            getRowClass()
            {
                return 'col-xs-' + (12 / this.pakketten.length);
            }
        },
        methods: {
            notifyChanges()
            {
                this.$emit('change', {
                    pakketten: this.pakketten,
                    foldcount: this.foldcount
                });
            },
            addPakket()
            {
                if(this.pakketten.length >= 4)
                {
                    alert('Max 4 pakketten');
                    return;
                }
                this.pakketten.push({
                    featured: false,
                    featured_text: "",
                    color: "none",
                    fold_description_more: "",
                    fold_description_less: "",
                    titel: 'Titel',
                    subtitel: 'Subtitel',
                    titel2: 'Titel 2',
                    subtitel2: 'Subtitel 2',
                    list: [],
                    button: {
                        label: "Tekst",
                        url: "/"
                    }
                });
                this.notifyChanges();
            },
            deletePakket(index)
            {
                this.pakketten.splice(index, 1);
                this.notifyChanges();
            },
            addItem(list)
            {
                list.push({
                    tekst: 'Tekst',
                    bold: false,
                    checked: true,
                    info: ''
                });
                this.notifyChanges();
            },
            deleteItem(list, index)
            {
                list.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let dataKeys = Object.keys(this.data);
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
            };
            for(let k of dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

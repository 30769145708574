<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-6">
            <div class="row">
                <div class="col-xs-12">
                    <KendoValueComponent label="Titel" v-model="title" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent label="Tekst" v-model="content" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <label class="form-label">Tekst actieknop</label>
                        <input type="text" class="form-control" v-model="buttonText">
                    </div>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent label="Feedback: Toegevoegd aan mailinglijst" v-model="successMessage" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <KendoValueComponent label="Feedback: E-mailadres staat al op mailinglijst" v-model="alreadyOnListMessage" :tools="tools"/>
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <label>Mailchimp mailinglist-id</label>
                        <input type="text" class="form-control" v-model="mailchimpId">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xs-6">
            <ImageUploadComponent label="Afbeelding rechts" v-model="mainImage" containerStyle="float:left;border:1px solid green;" />
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>
<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>

        <div class="col-xs-12">
            <KendoValueComponent v-model="titel" label="Linker blok titel" :tools="tools"/>
        </div>
        <div class="col-xs-12" style="margin-top:15px;border:1px dashed #ccc;">
            <div v-for="(item, index) in klantLogos" style="margin-bottom:15px;" class="row">
                <!--<KendoValueComponent v-model="item.titel" @change="notifyChanges" :label="'Accordion titel '+(index+1)" :tools="tools"/>-->
                    <div class="col-xs-6">
                    <ImageUploadComponent label="Logo klant" @change="notifyChanges" v-model="item.logo" />
                    <button class="btn btn-sm btn-danger" @click.prevent="deleteListItem(index)">Verwijderen</button>
                    </div>
                    <div class="col-xs-6">
                        <div class="row">
                            <div class="col-xs-12">
                                <ImageUploadComponent label="Afbeelding rechts" v-model="item.largeImage" containerStyle="float:left;border:1px solid green;" />
                            </div>
                            <div class="col-xs-12">
                                <label style="font-weight: bold;">testimonial titel</label>
                                <input class="form-control" v-model="item.testimonialTekst1"/>
                            </div>
                            <div class="col-xs-12">
                                <KendoValueComponent v-model="item.testimonialTekst2" label="testimonial statistieken" :tools="tools"/>
                            </div>
                            <div class="col-xs-12">
                                <KendoValueComponent v-model="item.testimonialTekst3" label="testimonial slot" :tools="tools"/>
                            </div>
                        </div>
                    </div>
            </div>
            <button class="btn btn-sm btn-success" @click.prevent="addNewListItem">Nieuw klant logo toevoegen <i class="fas fa-plus"></i></button>
        </div>
        <div class="col-xs-12" style="margin-top:15px;">
            <ButtonComponent v-model="mainButton" />
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            },
            addNewListItem()
            {
                this.klantLogos.push({
                    logo: {
                        path: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
                        alt: 'Afbeelding 1',
                        visible: true
                    },
                    testimonialTekst1: 'Tekst 1',
                    testimonialTekst2: 'Tekst 2',
                    testimonialTekst3: 'Tekst 3',
                    largeImage: {
                        path: 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
                        alt: 'Afbeelding 2',
                        visible: true
                    }
                });
                this.notifyChanges();
            },
            deleteListItem(index)
            {
                this.klantLogos.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

<template>
<form>
<div class="abtest" v-for="test in data.tests">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>A/B Test {{ test.name }}</h5>
                    <div class="ibox-tools">
                        <a class="collapse-link">
                            <i class="fa fa-chevron-up"></i>
                        </a>
                    </div>
                </div>

                <div class="ibox-content" v-if="test.name == 'footer_cta'">

                    <div class="variant" v-for="(variant, variantIndex) in test.json.variants">
                        <h3>Variatie {{ variantIndex+1 }}</h3>
                        <div class="form-group">
                            <label for="variant" class="col-4 col-form-label">Variant naam</label> 
                            <div class="col-8">
                            <input id="variant" name="variant" type="text" class="form-control" @change="notifyChanges" v-model="variant.variant">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="col-4 col-form-label">A/B Test percentage</label> 
                            <div class="col-8">
                            <input id="ribbonPercentage" name="ribbonPercentage" type="number" class="form-control" v-model="variant.percentage">
                            </div>
                        </div> 

                        <KendoValueComponent v-model="variant.titel" label="Footer titel" :tools="tools" />
                        <KendoValueComponent v-model="variant.contents" label="Content gedeelte" :tools="tools" />

                        <ButtonComponent v-model="variant.button" />
                        
                    </div>


                </div>
                <div class="ibox-content" v-if="test.name == 'footer_cta_mkb'">

                    <div class="variant" v-for="(variant, variantIndex) in test.json.variants">
                        <h3>MKB Variatie {{ variantIndex+1 }}</h3>
                        <div class="form-group">
                            <label for="variant" class="col-4 col-form-label">Variant naam</label> 
                            <div class="col-8">
                            <input id="variant" name="variant" type="text" class="form-control" @change="notifyChanges" v-model="variant.variant">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="col-4 col-form-label">A/B Test percentage</label> 
                            <div class="col-8">
                            <input id="ribbonPercentage" name="ribbonPercentage" type="number" class="form-control" v-model="variant.percentage">
                            </div>
                        </div> 

                        <KendoValueComponent v-model="variant.titel" label="Footer titel" :tools="tools" />
                        <KendoValueComponent v-model="variant.contents" label="Content gedeelte" :tools="tools" />

                        <ButtonComponent v-model="variant.button" />
                        
                    </div>


                </div>
                <div class="ibox-content" v-if="test.name == 'top_menu'">

                    <div class="variant" v-for="(variant, variantIndex) in test.json.variants">
                        <h3>Variatie {{ variantIndex+1 }}</h3>
                        <div class="form-group">
                            <label for="variant" class="col-4 col-form-label">Variant naam</label> 
                            <div class="col-8">
                            <input id="variant" name="variant" type="text" class="form-control" @change="notifyChanges" v-model="variant.variant">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="col-4 col-form-label">A/B Test percentage</label> 
                            <div class="col-8">
                            <input id="ribbonPercentage" name="ribbonPercentage" type="number" class="form-control" v-model="variant.percentage">
                            </div>
                        </div> 

                        <ButtonComponent v-model="variant.button" />
                        
                    </div>
                </div>
                <div class="ibox-content" v-if="test.name == 'ribbon'">

                    <div class="variant" v-for="(variant, variantIndex) in test.json.variants">
                        <h3>Variatie {{ variantIndex+1 }}</h3>
                        <div class="form-group">
                            <label for="variant" class="col-4 col-form-label">Variant naam</label> 
                            <div class="col-8">
                            <input id="variant" name="variant" type="text" class="form-control" @change="notifyChanges" v-model="variant.variant" disabled>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="col-4 col-form-label">A/B Test percentage</label> 
                            <div class="col-8">
                            <!--<input id="ribbonPercentage" name="ribbonPercentage" type="number" class="form-control" v-model="variant.percentage">-->
                            <b>Ingeschakeld via trigger SalesFeed</b>
                            </div>
                        </div> 

                        <KendoValueComponent v-model="variant.contents" label="Inhoud ribbon" :tools="tools" />
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
                    <div class="form-group row">
                        <div class="offset-4 col-8">
                        <button name="submit" type="submit" class="btn btn-primary" @click.prevent="submitChanges">Opslaan</button>
                        </div>
                    </div>
<pre style="display: none;">{{ JSON.stringify(data, null, 2) }}</pre>
</form>

</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            //for(let k of this.dataKeys)
            //    this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {

            },
            submitChanges()
            {
                const URL = '/abtest';
                axios.post(URL, this.data).then((response) => {
                    console.log(response);
                }).catch((err) => {
                    alert("Er is iets fout gegaan met het ophalen van de paginas. Error: " + err);
                });
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting","viewHtml"],
            };

            return obj;
        },
        props: ['data']
    }
</script>

<template>
<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-6">
        <KendoValueComponent v-model="titel" label="Blok titel" :tools="tools"/>

        <ImageUploadComponent label="Standaard afbeelding" v-model="mainImage" containerStyle="float:left;border:1px solid green;" />
        </div>

        <div class="col-xs-6">
            <div class="row">

                <div class="col-xs-12" style="margin-top:15px;border:1px dashed #ccc;">
                    <div v-for="(item, index) in accordion" style="margin-bottom:15px;">
                        <KendoValueComponent v-model="item.titel" @change="notifyChanges" :label="'Accordion titel '+(index+1)" :tools="tools"/>
                        <KendoValueComponent v-model="item.tekst" @change="notifyChanges" :label="'Accordion tekst '+(index+1)" :tools="tools"/>
                        <ImageUploadComponent label="Accordion afbeelding" v-model="item.image" containerStyle="" />

                        <button class="btn btn-sm btn-danger" @click.prevent="deleteAccordionItem(index)">Verwijderen</button>
                    </div>
                    <button class="btn btn-sm btn-success" @click.prevent="addNewAccordionItem">Nieuwe rij toevoegen <i class="fas fa-plus"></i></button>
                </div>
                <div class="col-xs-12" style="margin-top:15px;">
                    <ButtonComponent v-model="mainButton" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            },
            addNewAccordionItem()
            {
                this.accordion.push({
                    titel: "Titel",
                    tekst: "Placeholder tekst",
                    image: {
                      path: '',
                      alt: '',
                      visible: false
                    }
                });
                this.notifyChanges();
            },
            deleteAccordionItem(index)
            {
                this.accordion.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>

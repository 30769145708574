<template>
<div class="container-fluid block-usp-list">
    <div class="row">
        <div class="col-xs-12">
          <label>Anchor</label>
          #<input class="form-control inlined" v-model="anchor" v-on:change="notifyChanges" />
        </div>
        <div class="col-xs-12">
            <div class="form-group">
                <KendoValueComponent label="Titel" v-model="title.value" v-on:change="notifyChanges" :tools="tools"/>
            </div>
            <div class="form-group">
                <label>Titel stijl</label>
                <select class="form-control" @change="notifyChanges" @input="notifyChanges" v-model="title.font" placeholder="Titel">
                    <option value="">-geen stijl-</option>
                    <option value="schreef">schreef</option>
                    <option value="schreefloos">schreefloos</option>
                </select>
            </div>
            <div class="form-group" style="clear:both;">
                <KendoValueComponent v-model="subtitle.value" v-on:change="notifyChanges" label="Subtitel" :tools="tools"/>
            </div>            
        </div>
        <div class="col-xs-12" v-for="(item, index) in usps">
            <div class="usp-item">
            Tekst inclusief word-break: <input v-model="item.value.label" class="form-control" type="text" disabled />
            <KendoValueComponent v-model="item.value.label" v-on:change="notifyChanges" label="" :tools="tools"/>
            
            Link: <input v-model="item.value.url" class="form-control" v-on:change="notifyChanges" list="pages" />
            <button class="btn btn-sm btn-danger delete-button" @click.prevent="deleteItem(index)">USP verwijderen</button>
            </div>
        </div>
        <div class="col-xs-12" style="margin-top:15px;">
            <button class="btn btn-sm btn-primary" @click.prevent="addItem">USP toevoegen</button>
        </div>
    </div>
</div>
</template>

<style lang="scss">
    .block-usp-list {
        .usp-item {
            border: 1px solid #ccc;
            border-left: 5px solid #999;
            margin-bottom: 15px;
            position: relative;
            padding: 10px;
            
            .delete-button {
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
</style>

<script type="application/javascript">
    export default {
        mounted()
        {
            for(let k of this.dataKeys)
                this.$watch(k, this.notifyChanges, { immediate: true });
        },
        methods: {
            notifyChanges()
            {
                let obj = {};
                for(let k of this.dataKeys)
                    Vue.set(obj, k, this[k]);
                this.$emit('change', obj);
            },
            addItem()
            {
                this.usps.push({
                    value: {
                        label: "Tekst",
                        url: "https://google.com"
                    }
                });
                this.notifyChanges();
            },
            deleteItem(index)
            {
                this.usps.splice(index, 1);
                this.notifyChanges();
            }
        },
        data()
        {
            let obj = {
                tools: ["cut", "copy", "paste", "bold", "italic", "superscript", "underline", "insertUnorderedList", "insertOrderedList", "createLink", "unlink", {
                        name: "custom",
                        tooltip: "Wordbreak toevoegen",
                        exec: function (e) {
                            var editor = $(this).data("kendoEditor");
                            editor.exec("inserthtml", { value: "&shy;" });
                        }
                    }, "cleanFormatting"],
                dataKeys: Object.keys(this.data),
            };
            for(let k of obj.dataKeys)
                Vue.set(obj, k, this.data[k]);
            return obj;
        },
        props: ['data']
    }
</script>
